import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";

export function useSocialIcons(props) {
	const blockFacebook = computed(() => {
		if (!props.settings?.["facebook-show"]) return "";
		return renderData(props.settings.facebook, props.dataSite);
	});

	const blockLinkedin = computed(() => {
		if (!props.settings?.["linkedin-show"]) return "";
		return renderData(props.settings.linkedin, props.dataSite);
	});

	const blockTwitter = computed(() => {
		if (!props.settings?.["twitter-show"]) return "";
		return renderData(props.settings.twitter, props.dataSite);
	});

	const blockYoutube = computed(() => {
		return props.settings.youtube || null;
	});

	const hasSocialIcons = computed(() => {
		return (
			blockFacebook.value ||
			blockLinkedin.value ||
			blockTwitter.value ||
			blockYoutube.value
		);
	});

	return {
		blockFacebook,
		blockLinkedin,
		blockTwitter,
		blockYoutube,
		hasSocialIcons,
	};
}
